import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import {
  MEDIA_QUERY_WIDTH_DESKTOP_1200,
  MEDIA_QUERY_WIDTH_DESKTOP_M,
  MEDIA_QUERY_WIDTH_DESKTOP_MOBILE,
  MEDIA_QUERY_WIDTH_DESKTOP_S,
  MEDIA_QUERY_WIDTH_MOBILE_M,
} from '@/configs/mediaQuery';
import { FlexBox } from '../atom/FlexBox';

const FullWidthWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_1200}) {
    display: none;
  }
`;

const Wrapper = styled(FlexBox)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_M}) {
    padding: 0 80px;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    padding: 0 52px;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    padding: 0;
  }
`;

const StepBarFill = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 120vw;
  border-radius: 0 9999px 9999px 0;
  background: ${(p) => p.theme.colors.point};
  border: 1px solid ${(p) => p.theme.colors.textWhite};
  border-left: 0;
`;

const StepBar = styled(motion.div)`
  position: relative;
  display: flex;
  width: 100%;
  background: rgba(255, 255, 255, 0.08);
  border-top: 1px solid rgba(248, 248, 248, 0.4);
  border-bottom: 1px solid rgba(248, 248, 248, 0.4);
  overflow: hidden;
`;

const Card = styled(motion.div)`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  z-index: 1;
  height: 100%;
  padding: 40px 8px;
  align-items: center;

  ul {
    text-align: center;
    li {
      font-size: ${(p) => p.theme.size.md};
      white-space: pre-line;
    }
  }
  @media (${MEDIA_QUERY_WIDTH_DESKTOP_1200}) {
    padding: 64px 8px;
  }
`;

const CardTitleContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: ${(p) => p.theme.colors.textWhite};
  text-align: center;

  font-size: 40px;
  font-weight: 900;
  line-height: 36px;

  word-break: keep-all;

  h3 {
    font-size: 24px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (${MEDIA_QUERY_WIDTH_MOBILE_M}) {
    padding: 0 300px;
  }
`;

const MobileContainer = styled.div`
  display: none;
  flex-direction: column;
  width: 480px;
  border: 1px solid rgba(248, 248, 248, 0.4);
  border-radius: 32px;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_1200}) {
    display: flex;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    width: 332px;
  }
`;

export default function Roadmap() {
  const { t } = useTranslation('landing');

  return (
    <Wrapper $flex $justify="center" $align="center">
      <MobileContainer>
        <Card
          initial={{
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
            transition: {
              duration: 0.5,
              ease: 'linear',
            },
          }}
        >
          <CardTitleContainer
            initial={{
              color: '#ffffff',
            }}
            whileInView={{
              color: '#3861D1',
              transition: {
                duration: 0.5,
                ease: 'linear',
              },
            }}
          >
            Q1
            <h3>{t('btcfiEcosystem')}</h3>
          </CardTitleContainer>
          <ul>
            <li>{t('integrateAll')}</li>
          </ul>
        </Card>
        <Card
          initial={{
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
            transition: {
              duration: 1,
              ease: 'linear',
            },
          }}
        >
          <CardTitleContainer
            initial={{
              color: '#ffffff',
            }}
            whileInView={{
              color: '#3861D1',
              transition: {
                duration: 1,
                ease: 'linear',
              },
            }}
          >
            Q2
            <h3>{t('launchAutomatic')}</h3>
          </CardTitleContainer>
          <ul>
            <li>{t('automaticOneClick')}</li>
          </ul>
        </Card>
        <Card
          initial={{
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
            transition: {
              duration: 1.5,
              ease: 'linear',
            },
          }}
        >
          <CardTitleContainer
            initial={{
              color: '#ffffff',
            }}
            whileInView={{
              color: '#3861D1',
              transition: {
                duration: 1.5,
                ease: 'linear',
              },
            }}
          >
            Q3
            <h3>{t('supportNativeBtc')}</h3>
          </CardTitleContainer>
          <ul>
            <li>{t('nativeBtcDeposit')}</li>
          </ul>
        </Card>
      </MobileContainer>
      <FullWidthWrapper>
        <StepBar>
          <StepBarFill
            initial={{
              x: '-99%',
            }}
            whileInView={{
              x: '0%',
              transition: {
                duration: 1.5,
                ease: 'linear',
              },
            }}
          />
          <ContentContainer>
            <Card
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                  ease: 'linear',
                },
              }}
            >
              <CardTitleContainer>
                Q1
                <h3>{t('btcfiEcosystem')}</h3>
              </CardTitleContainer>
              <ul>
                <li>{t('integrateAll')}</li>
              </ul>
            </Card>
            <Card
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
                transition: {
                  duration: 1,
                  ease: 'linear',
                },
              }}
            >
              <CardTitleContainer>
                Q2
                <h3>{t('launchAutomatic')}</h3>
              </CardTitleContainer>
              <ul>
                <li>{t('automaticOneClick')}</li>
              </ul>
            </Card>
            <Card
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
                transition: {
                  duration: 1.5,
                  ease: 'linear',
                },
              }}
            >
              <CardTitleContainer>
                Q3
                <h3>{t('supportNativeBtc')}</h3>
              </CardTitleContainer>
              <ul>
                <li>{t('nativeBtcDeposit')}</li>
              </ul>
            </Card>
          </ContentContainer>
        </StepBar>
      </FullWidthWrapper>
    </Wrapper>
  );
}
