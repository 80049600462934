import React from 'react';

export default function c({
  size = 20,
  color = '#f8f8f8',
}: {
  size?: number;
  color?: string;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM9.69797 5.47067C11.0918 5.08654 12.474 6.14558 12.4717 7.61412C12.4714 7.78861 12.451 7.95921 12.4124 8.12339C12.1554 8.12418 11.8928 8.15893 11.6295 8.23147L7.31062 9.42127L5.6026 6.59888L9.69797 5.47067ZM13.2529 7.61535C13.2526 7.82382 13.2309 8.02836 13.1897 8.22653C14.4327 8.56607 15.3917 9.70852 15.3917 11.1246C15.3917 12.4724 14.4989 13.657 13.2056 14.0163L8.0208 15.456L5.47236 11.2449L10.8578 9.74952C11.3404 9.61556 11.7486 9.32466 12.0338 8.93988C11.9683 8.95167 11.9027 8.96656 11.837 8.98466L6.94826 10.3314L4.39959 6.11994L9.49044 4.71749C11.391 4.19376 13.256 5.63942 13.2529 7.61535ZM11.0668 10.5023C11.8963 10.272 12.5609 9.70215 12.931 8.96748C13.8722 9.19873 14.6105 10.0519 14.6105 11.1246C14.6105 12.1253 13.948 12.9992 12.9966 13.2635L8.38251 14.5447L6.67425 11.722L11.0668 10.5023Z"
        fill={color}
      />
    </svg>
  );
}
