import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_DESKTOP_MOBILE } from '@/configs/mediaQuery';
import Accordion from '../atom/Accordion';
import { FlexBox } from '../atom/FlexBox';
import { GlassPanel } from '../atom/GlassPanel';
import { StyledText } from '../atom/Text';

const Wrapper = styled(GlassPanel)`
  flex: 1;
  max-width: 1200px;
  background: #ffffff14;
  border-radius: 40px;

  hr {
    margin: 24px 0;
    border: 1px solid #818da666;
    border-bottom: 0;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    padding: 24px;
    h3 {
      font-size: ${(p) => p.theme.size.xl};
    }
  }
`;

const FaqWrapper = styled(FlexBox)`
  gap: 40px;
`;

function Faq() {
  const { t } = useTranslation('landing');

  const faqs = useMemo(
    () =>
      t('faq', { returnObjects: true }) as {
        question: string;
        answer: string;
      }[],
    [t]
  );

  return (
    <Wrapper $flex $direction="column" $padding={40}>
      <StyledText as="h3" $fontSize="xxxl" $fontWeight="bold">
        About BTCFi
      </StyledText>
      <hr />
      <FaqWrapper $flex $direction="column">
        {faqs?.map((faq) => (
          <Accordion
            key={faq.question}
            title={faq.question}
            content={faq.answer}
          />
        ))}
      </FaqWrapper>
    </Wrapper>
  );
}

export default Faq;
