import Alex from '@/assets/images/partners/alex.png';
import Conflux from '@/assets/images/partners/conflux.png';
import Neo from '@/assets/images/partners/neo.png';
import Stacks from '@/assets/images/partners/stacks.png';
import Theori from '@/assets/images/partners/theori.png';
import XLink from '@/assets/images/partners/xlink.png';

export const PARTNERS = [
  {
    name: 'Thori',
    image: Theori,
  },
  {
    name: 'XLink',
    image: XLink,
  },
  {
    name: 'Alex',
    image: Alex,
  },
  {
    name: 'Conflux',
    image: Conflux,
  },
  {
    name: 'Neo',
    image: Neo,
  },
  {
    name: 'Stacks',
    image: Stacks,
  },
];
