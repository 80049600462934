import { useTranslation } from 'next-i18next';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Pagination, Navigation, EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
// @ts-ignore
import IconArrowBackDark from '@/assets/icons/icon-arrow-back-dark.svg?url';
import BG1 from '@/assets/images/bg1.png';
import BG2 from '@/assets/images/bg2.png';
import BG3 from '@/assets/images/bg3.png';
import { MEDIA_QUERY_WIDTH_DESKTOP_M } from '@/configs/mediaQuery';
import {
  VIDEO_URL_HOW1_MP4,
  VIDEO_URL_HOW2_MP4,
  VIDEO_URL_HOW3_MP4,
} from '@/configs/videoUrl';
import Slide from './Slide';

const Wrapper = styled.div`
  max-width: 1500px;

  user-select: none;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_M}) {
    display: none;
  }

  .swiper {
    position: static;
  }

  .swiper-slide:not(
      .swiper-slide-active,
      .swiper-slide-prev,
      .swiper-slide-next
    ) {
    visibility: hidden;
  }

  .swiper-slide-active {
    width: 516px !important;
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    width: 480px !important;
    opacity: 0.6;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    content: '' !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 48px;
    height: 48px;
  }

  .swiper-button-prev {
    left: 250px;
  }

  .swiper-button-next {
    right: 250px;
  }

  .swiper-button-prev {
    background: url(${IconArrowBackDark.src});
  }

  .swiper-button-next {
    transform: rotate(180deg);
    background: url(${IconArrowBackDark.src});
  }
`;

const CardContainer = styled.div`
  margin: 0 72px;
`;

export default function HowToWorksSlider() {
  const { t } = useTranslation('landing');
  const [slideIndex, setSlideIndex] = useState(0);

  const handleActiveIndexChange = useCallback((swiper: SwiperClass) => {
    setSlideIndex(swiper.realIndex);
  }, []);

  return (
    <Wrapper>
      <Swiper
        effect="coverflow"
        slidesPerView={3}
        grabCursor
        centeredSlides
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 1100,
          modifier: 1,
          slideShadows: true,
          scale: 1.3,
        }}
        modules={[Pagination, Navigation, EffectCoverflow]}
        loop
        navigation
        initialSlide={0}
        onActiveIndexChange={handleActiveIndexChange}
        onSlideChange={handleActiveIndexChange}
      >
        <CardContainer>
          <SwiperSlide>
            <Slide
              isActive={slideIndex === 0}
              videoPath={VIDEO_URL_HOW1_MP4}
              title={t('depositBTC')}
              description={t('stakeBTCFrom')}
              bgImage={BG1}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              isActive={slideIndex === 1}
              videoPath={VIDEO_URL_HOW2_MP4}
              title={t('mintBtcUsd')}
              description={t('getBtcUsdAStable')}
              bgImage={BG2}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              isActive={slideIndex === 2}
              videoPath={VIDEO_URL_HOW3_MP4}
              title={t('useBtcUsd')}
              description={t('experienceTheFreedom')}
              bgImage={BG3}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              isActive={slideIndex === 3}
              videoPath={VIDEO_URL_HOW1_MP4}
              title={t('depositBTC')}
              description={t('stakeBTCFrom')}
              bgImage={BG1}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              isActive={slideIndex === 4}
              videoPath={VIDEO_URL_HOW2_MP4}
              title={t('mintBtcUsd')}
              description={t('getBtcUsdAStable')}
              bgImage={BG2}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              isActive={slideIndex === 5}
              videoPath={VIDEO_URL_HOW3_MP4}
              title={t('useBtcUsd')}
              description={t('experienceTheFreedom')}
              bgImage={BG3}
            />
          </SwiperSlide>
        </CardContainer>
      </Swiper>
    </Wrapper>
  );
}
