import { useTranslation } from 'next-i18next';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { Pagination, Navigation, EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// @ts-ignore
import { SwiperClass } from 'swiper/swiper-react';
// @ts-ignore
import IconArrowBackDark from '@/assets/icons/icon-arrow-back-dark.svg?url';
import BG1 from '@/assets/images/bg1.png';
import BG2 from '@/assets/images/bg2.png';
import BG3 from '@/assets/images/bg3.png';
import { MEDIA_QUERY_WIDTH_DESKTOP_M } from '@/configs/mediaQuery';
import {
  VIDEO_URL_HOW1_MP4,
  VIDEO_URL_HOW2_MP4,
  VIDEO_URL_HOW3_MP4,
} from '@/configs/videoUrl';
import Slide from './Slide';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  display: none;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_M}) {
    display: block;
  }

  .swiper {
    position: static;
  }

  .swiper-wrapper {
    height: unset;
  }

  .swiper,
  .swiper-slide {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  @media (max-width: 540px) {
    top: 100%;
    gap: 24px;
    justify-content: center;
    margin-top: 24px;
  }
`;

const PrevButton = styled.button`
  background: url(${IconArrowBackDark.src});
  width: 48px;
  height: 48px;
  z-index: 1;
`;

const NextButton = styled(PrevButton)`
  transform: rotate(180deg);
  z-index: 1;
`;

export default function HowToWorksSlider() {
  const { t } = useTranslation('landing');
  const [slideIndex, setSlideIndex] = useState(0);

  const handleActiveIndexChange = useCallback((swiper: SwiperClass) => {
    setSlideIndex(swiper.realIndex);
  }, []);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <Wrapper>
      <Swiper
        slidesPerView={1}
        grabCursor
        spaceBetween={50}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
          enabled: true,
        }}
        modules={[Pagination, Navigation, EffectCoverflow]}
        loop
        initialSlide={0}
        onActiveIndexChange={handleActiveIndexChange}
        onSlideChange={handleActiveIndexChange}
      >
        <SwiperSlide>
          <Slide
            isActive={slideIndex === 0}
            videoPath={VIDEO_URL_HOW1_MP4}
            title={t('depositBTC')}
            description={t('stakeBTCFrom')}
            bgImage={BG1}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Slide
            isActive={slideIndex === 1}
            videoPath={VIDEO_URL_HOW2_MP4}
            title={t('mintBtcUsd')}
            description={t('getBtcUsdAStable')}
            bgImage={BG2}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Slide
            isActive={slideIndex === 2}
            videoPath={VIDEO_URL_HOW3_MP4}
            title={t('useBtcUsd')}
            description={t('experienceTheFreedom')}
            bgImage={BG3}
          />
        </SwiperSlide>
      </Swiper>
      <ButtonContainer>
        <PrevButton ref={prevRef} />
        <NextButton ref={nextRef} />
      </ButtonContainer>
    </Wrapper>
  );
}
