import { motion } from 'framer-motion';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import IconBifrost from '@/assets/icons/icon-bifrost';
import { MEDIA_QUERY_WIDTH_DESKTOP_MOBILE } from '@/configs/mediaQuery';
import { PARTNERS } from '@/configs/partners';
import Roadmap from './Roadmap';
import { FlexBox } from '../atom/FlexBox';
import { StyledText } from '../atom/Text';

const Logo = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-size: 84px;
    ${(p) => p.theme.font.black};
    color: #ff474c;
    line-height: 1;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    span {
      font-size: 40px;
    }
  }
`;

const FullWidthWrapper = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  overflow: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;

  & > div:nth-child(1) {
    gap: 160px;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    gap: 44px;

    & > div:nth-child(1) {
      gap: 44px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 80px;
`;

const SlideWrapper = styled.div`
  display: flex;
  position: relative;
`;

const SlideLane = styled.div`
  display: flex;
  align-items: center;
  height: 104px;
  gap: 64px;
  opacity: 0.4;
  animation: slide 100s linear infinite forwards;
  border: 1px solid rgba(129, 141, 166, 0.4);
  background: rgba(255, 255, 255, 0.08);
  will-change: transform;

  @keyframes slide {
    100% {
      transform: translateX(-50%);
    }
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    height: 72px;
  }
`;

const SlideCar = styled.div`
  width: 240px;
  display: flex;
  padding: 20px;
  overflow: hidden;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    width: 160px;
  }
`;

const TitleContainer = styled(FlexBox)`
  margin: 0 auto;
  padding: 0 24px;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    p {
      font-size: ${(p) => p.theme.size.md};
    }

    div {
      > span {
        font-size: ${(p) => p.theme.size.lg};
      }
    }
  }

  @media (max-width: 520px) {
    p {
      white-space: normal !important;
    }
  }
`;

export default function Partners() {
  const { t } = useTranslation('landing');
  const isMobile = useMediaQuery({
    query: `(${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE})`,
  });

  return (
    <Wrapper>
      <FlexBox $flex $direction="column" $gap={isMobile ? 44 : 160}>
        <TitleContainer
          $flex
          $direction="column"
          $justify="center"
          $align="center"
          $gap={44}
        >
          <FlexBox
            as={motion.div}
            $flex
            $direction="column"
            $justify="center"
            $align="center"
            $gap="xl"
            initial={{ y: 20, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                type: 'keyframes',
                duration: 1,
              },
            }}
            viewport={{ once: true }}
          >
            <Logo>
              <IconBifrost size={isMobile ? 32 : 80} color="#ff474c" />
              <span>Bifrost</span>
            </Logo>
          </FlexBox>
          <StyledText
            as={motion.p}
            style={{
              whiteSpace: 'pre-line',
            }}
            $fontSize={isMobile ? 'md' : 'xl'}
            $textColor="textWhite"
            $textAlign="center"
            initial={{ y: 20, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                type: 'keyframes',
                duration: 1.5,
              },
            }}
            viewport={{ once: true }}
          >
            {t('btcfiIsBuilt')}
          </StyledText>
        </TitleContainer>
        <FullWidthWrapper>
          <Container>
            <SlideWrapper>
              <SlideLane>
                {[...PARTNERS, ...PARTNERS, ...PARTNERS, ...PARTNERS].map(
                  (partner, index) => (
                    <SlideCar key={`1_${index}`}>
                      <Image src={partner.image} alt={partner.name} />
                    </SlideCar>
                  )
                )}
              </SlideLane>
            </SlideWrapper>
          </Container>
        </FullWidthWrapper>
      </FlexBox>
      <Roadmap />
    </Wrapper>
  );
}
