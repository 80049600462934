import Image, { StaticImageData } from 'next/image';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FlexBox } from '@/components/atom/FlexBox';
import { StyledText } from '@/components/atom/Text';
import {
  MEDIA_QUERY_WIDTH_DESKTOP_MOBILE,
  MEDIA_QUERY_WIDTH_MOBILE_S,
} from '@/configs/mediaQuery';

const Wrapper = styled(FlexBox)`
  border-radius: 54px;
  max-width: 476px;
  overflow: hidden;
  position: relative;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    video {
      width: 284px;
    }

    max-width: 382px;
  }

  @media (${MEDIA_QUERY_WIDTH_MOBILE_S}) {
    max-width: unset;
  }
`;

const StyledFlexBox = styled(FlexBox)`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 32px 32px 32px;
  border-radius: 0 0 54px 54px;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    gap: ${(p) => p.theme.size.xs};
    h3 {
      font-size: ${(p) => p.theme.size.xl};
    }

    p {
      font-size: ${(p) => p.theme.size.lg};
      line-height: ${(p) => p.theme.size.xxl};
    }
  }
`;

const VideoWrapper = styled.div`
  video {
    width: 400px;
    aspect-ratio: 1;
    margin: auto;
  }
  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    video {
      width: 284px;
      aspect-ratio: 1;
      margin: auto;
    }
  }
`;

const BGWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

interface Props {
  videoPath: string;
  title: string;
  description: string;
  bgImage: StaticImageData;
  isActive: boolean;
}

function Slide({ videoPath, title, description, bgImage, isActive }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (isActive) {
      videoRef.current?.play().catch(() => {});
    } else {
      videoRef.current?.pause();
    }
  }, [isActive]);

  return (
    <Wrapper $flex $direction="column">
      <BGWrapper>
        <Image src={bgImage} alt="" style={{ height: '100%' }} />
      </BGWrapper>
      <VideoWrapper>
        <video ref={videoRef} autoPlay playsInline muted loop preload="auto">
          {typeof window !== 'undefined' && (
            <>
              <source
                src={videoPath.replace('mp4', 'webm')}
                type="video/webm"
              />
              <source src={videoPath} type="video/mp4" />
            </>
          )}
        </video>
      </VideoWrapper>
      <StyledFlexBox $flex $direction="column" $gap="md">
        <StyledText as="h3" $fontSize="xxxl" $fontWeight="bold">
          {title}
        </StyledText>
        <StyledText as="p" $fontSize="xl">
          {description}
        </StyledText>
      </StyledFlexBox>
    </Wrapper>
  );
}

export default Slide;
