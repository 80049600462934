import styled from 'styled-components';
import { FlexBox } from '@/components/atom/FlexBox';
import { Theme } from '@/styles/theme';
import { PaddingProps } from '@/types/ui';

export const GlassPanel = styled(FlexBox)<{
  $padding: PaddingProps | number;
  $rounded?: keyof Theme['rounded'];
  $noBorder?: boolean;
}>`
  ${(p) => !p.$noBorder && p.theme.border.gray};
  padding: ${(p) =>
    typeof p.$padding === 'object'
      ? `${p.theme.size[p.$padding.y]} ${p.theme.size[p.$padding.x]}`
      : typeof p.$padding === 'number'
        ? `${p.$padding}px`
        : p.theme.size[p.$padding]};
  background-color: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(4px);
  ${(p) => p.theme.rounded[p.$rounded ?? 'md']};
`;
