import { isNormalPositive } from '@bifrost-platform/bifront-sdk-react-wallet';
import { useCallback, useMemo, useRef } from 'react';
import CountUp from 'react-countup';
import styled from 'styled-components';
import {
  MEDIA_QUERY_WIDTH_DESKTOP_1200,
  MEDIA_QUERY_WIDTH_DESKTOP_MOBILE,
  MEDIA_QUERY_WIDTH_DESKTOP_S,
} from '@/configs/mediaQuery';

const TVLValueText = styled.p<{ $isScrolled: boolean }>`
  color: ${(p) => p.theme.colors.textWhite};
  font-size: 48px;
  text-align: start;
  font-weight: 900;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_1200}) {
    font-size: ${(p) => p.theme.size.xxxl};
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    font-size: ${(p) => p.$isScrolled && p.theme.size.xl};
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    font-size: ${(p) => p.theme.size.xl};
  }
`;

export const CountingData = ({
  value,
  decimal = 0,
  prefix = '',
  suffix = '',
  isScrolled = false,
  delay,
  ref,
  ...rest
}: {
  value: string;
  decimal: number;
  prefix?: string;
  suffix?: string;
  isScrolled?: boolean;
  delay?: number;
  ref?: React.Ref<HTMLDivElement>;
}) => {
  const end = useMemo(
    () => (isNormalPositive(value, true) ? Number(value) : 0),
    [value]
  );
  const start = useMemo(() => end / 2, [end]);
  const decimalPlaces = useMemo(() => decimal, [decimal]);
  const tvlRef = useRef<HTMLDivElement>(null);
  const tvlCountRef = useRef<HTMLDivElement>(null);

  const handleCompleteCount = useCallback(() => {
    if (tvlRef.current) {
      tvlRef.current.remove();
    }
    if (tvlCountRef.current) {
      tvlCountRef.current.style.bottom = '0';
      tvlCountRef.current.style.position = 'relative';
    }
  }, []);

  return (
    <div style={{ marginTop: '12px' }}>
      <TVLValueText
        $isScrolled={isScrolled}
        ref={tvlRef}
        style={{ visibility: 'hidden' }}
        {...rest}
      >
        {prefix}
        {isNormalPositive(value, true) ? (
          <CountUp
            scrollSpyOnce
            scrollSpyDelay={500}
            onEnd={handleCompleteCount}
            duration={0}
            end={end}
            start={start}
            decimalPlaces={decimalPlaces}
            delay={delay}
            enableScrollSpy
          >
            {({ countUpRef }) => <span ref={countUpRef} />}
          </CountUp>
        ) : (
          '-'
        )}
      </TVLValueText>
      <TVLValueText
        ref={tvlCountRef}
        $isScrolled={isScrolled}
        style={{ position: 'absolute', bottom: 24 }}
        {...rest}
      >
        {prefix}
        {isNormalPositive(value, true) ? (
          <CountUp
            scrollSpyOnce
            scrollSpyDelay={500}
            onEnd={handleCompleteCount}
            duration={3}
            end={end}
            start={start}
            decimalPlaces={decimalPlaces}
            delay={delay}
            enableScrollSpy
          >
            {({ countUpRef }) => <span ref={countUpRef} />}
          </CountUp>
        ) : (
          '-'
        )}
      </TVLValueText>
    </div>
  );
};
