import Image, { StaticImageData } from 'next/image';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FlexBox } from '@/components/atom/FlexBox';
import { StyledText } from '@/components/atom/Text';

const Wrapper = styled(FlexBox)`
  border-radius: 54px;
  max-width: 516px;
  min-height: 669px;
  position: relative;
  overflow: hidden;
`;

const StyledFlexBox = styled(FlexBox)`
  padding: 0 32px 32px 32px;
  border-radius: 0 0 54px 54px;
  flex: 1;
  justify-content: center;
  background: transparent;

  p {
    .swiper-slide-prev &,
    .swiper-slide-next & {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

const VideoWrapper = styled.div`
  padding: 0 60px;
  height: 440px;
  display: flex;
  justify-content: center;

  video {
    width: 440px;
    aspect-ratio: 1;
    margin: 0 auto;
  }
`;

const BGWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;

  video {
    height: 100%;
    object-fit: cover;
  }
`;

interface Props {
  isActive: boolean;
  videoPath: string;
  title: string;
  description: string;
  bgImage: StaticImageData;
}

function Slide({ isActive, videoPath, title, description, bgImage }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      if (isActive) {
        videoRef.current.play().catch(() => {});
      } else {
        videoRef.current.currentTime = 0.1;
        videoRef.current.pause();
      }
    }
  }, [isActive]);

  return (
    <Wrapper $flex $direction="column">
      <BGWrapper>
        <Image src={bgImage} alt="" style={{ height: '100%' }} />
      </BGWrapper>
      <VideoWrapper>
        <video ref={videoRef} muted loop autoPlay playsInline>
          {typeof window !== 'undefined' && (
            <>
              <source
                src={videoPath.replace('mp4', 'webm')}
                type="video/webm"
              />
              <source src={videoPath} type="video/mp4" />
            </>
          )}
        </video>
      </VideoWrapper>
      <StyledFlexBox $flex $direction="column" $gap="md">
        <StyledText as="h3" $fontSize={36} $fontWeight="bold">
          {title}
        </StyledText>
        <StyledText as="p" $fontSize="lg">
          {description}
        </StyledText>
      </StyledFlexBox>
    </Wrapper>
  );
}

export default Slide;
