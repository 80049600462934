import { motion } from 'framer-motion';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import IconPlus from '@/assets/icons/icon-plus.svg';
import { FlexBox } from '@/components/atom/FlexBox';
import { MEDIA_QUERY_WIDTH_DESKTOP_MOBILE } from '@/configs/mediaQuery';
import useToggle from '@/hooks/useToggle';

const Wrapper = styled.div<{ $isExpand?: boolean }>`
  display: block;
  width: 100%;
  position: relative;
  padding: 8px 0;

  aside {
    ${(p) => p.theme.transition.default};
    transition-duration: 0.4s;
    transform: ${(p) =>
      p.$isExpand ? 'translateY(6px) rotate(135deg);' : 'translateY(6px);'};

    @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
      transform: ${(p) =>
        p.$isExpand ? 'rotate(135deg);' : 'translateY(0px);'};
    }
  }

  p {
    padding-left: 40px;
    ${(p) => p.theme.transition.default};
    transition-duration: 0.4s;
    ${(p) => (p.$isExpand ? '' : p.theme.ally.srOnly)};
    ${(p) =>
      p.$isExpand
        ? css`
            opacity: 1;
            transform: translateX(0);
          `
        : css`
            opacity: 0;
            transform: translateX(-16px);
          `};
  }
`;

const AccordionSummary = styled(FlexBox)`
  font-size: ${(p) => p.theme.size.xl};
  font-weight: 700;
  line-height: 1.5;
  outline: none;
  text-align: start;

  ${(p) => p.theme.transition.default};
  &:hover,
  &:focus-visible {
    filter: brightness(0.8);
  }
  &:active {
    filter: brightness(0.6);
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    font-size: ${(p) => p.theme.size.md};
  }
`;

const AccordionDetail = styled(motion.p)`
  margin-top: 8px;
  word-break: break-all;
  font-size: ${(p) => p.theme.size.md};
  line-height: 1.5;
  color: ${(p) => p.theme.colors.textGray};
  white-space: pre-line;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    font-size: ${(p) => p.theme.size.xs};
  }
`;

interface Props {
  title: string;
  content: string;
  onClick?: () => void;
}

function Accordion({ title, content, onClick }: Props) {
  const [isExpand, toggleIsExpand] = useToggle();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }

    toggleIsExpand();
  }, [onClick, toggleIsExpand]);

  return (
    <Wrapper $isExpand={isExpand}>
      <AccordionSummary
        as="button"
        onClick={handleClick}
        $flex
        $gap="md"
        $align="flex-start"
      >
        <aside>
          <IconPlus />
        </aside>
        {title}
      </AccordionSummary>
      <AccordionDetail>{content}</AccordionDetail>
    </Wrapper>
  );
}

export default Accordion;
