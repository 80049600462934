// origin
export const VIDEO_URL_ORIGIN = 'https://assets.thebifrost.io/btcfi';

// extension
export const VIDEO_URL_EXTENSION_WEBM = '.webm';
export const VIDEO_URL_EXTENSION_MP4 = '.mp4';

// path
export const VIDEO_PATH_WAVE = '/wave';
export const VIDEO_PATH_LAUNCHING = '/launching';
export const VIDEO_PATH_HOW1 = '/how1';
export const VIDEO_PATH_HOW2 = '/how2';
export const VIDEO_PATH_HOW3 = '/how3';

// webm
export const VIDEO_URL_WAVE_WEBM = `${VIDEO_URL_ORIGIN}${VIDEO_PATH_WAVE}${VIDEO_URL_EXTENSION_WEBM}`;
export const VIDEO_URL_LAUNCHING_WEBM = `${VIDEO_URL_ORIGIN}${VIDEO_PATH_LAUNCHING}${VIDEO_URL_EXTENSION_WEBM}`;

// mp4
export const VIDEO_URL_WAVE_MP4 = `${VIDEO_URL_ORIGIN}${VIDEO_PATH_WAVE}${VIDEO_URL_EXTENSION_MP4}`;
export const VIDEO_URL_LAUNCHING_MP4 = `${VIDEO_URL_ORIGIN}${VIDEO_PATH_LAUNCHING}${VIDEO_URL_EXTENSION_MP4}`;
export const VIDEO_URL_HOW1_MP4 = `${VIDEO_URL_ORIGIN}${VIDEO_PATH_HOW1}${VIDEO_URL_EXTENSION_MP4}`;
export const VIDEO_URL_HOW2_MP4 = `${VIDEO_URL_ORIGIN}${VIDEO_PATH_HOW2}${VIDEO_URL_EXTENSION_MP4}`;
export const VIDEO_URL_HOW3_MP4 = `${VIDEO_URL_ORIGIN}${VIDEO_PATH_HOW3}${VIDEO_URL_EXTENSION_MP4}`;
