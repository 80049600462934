import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { Pagination, Navigation, EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// @ts-ignore
import IconArrowBackDark from '@/assets/icons/icon-arrow-back-dark.svg?url';
import IconBtcUSD from '@/assets/icons/icon-btcusd';
import ImageSlider1 from '@/assets/images/image-slider-1.png';
import ImageSlider2 from '@/assets/images/image-slider-2.svg';
import ImageSlider3 from '@/assets/images/image-slider-3.svg';
import ImageSlider4 from '@/assets/images/image-slider-4.svg';
import {
  MEDIA_QUERY_WIDTH_DESKTOP_1200,
  MEDIA_QUERY_WIDTH_DESKTOP_MOBILE,
  MEDIA_QUERY_WIDTH_DESKTOP_S,
  MEDIA_QUERY_WIDTH_MOBILE_M,
  MEDIA_QUERY_WIDTH_MOBILE_S,
} from '@/configs/mediaQuery';
import { FlexBox } from '../atom/FlexBox';
import { GlassPanel } from '../atom/GlassPanel';
import { StyledText } from '../atom/Text';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1600px;
  text-align: center;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    gap: 40px;
  }

  .swiper {
    position: static;
  }

  .swiper-wrapper {
    height: unset;
  }

  .swiper,
  .swiper-slide {
    width: 100% !important;
    height: 100% !important;
    min-height: 608px;
    display: flex;
    justify-content: center;
  }
`;

const ImageContainer = styled(FlexBox)`
  padding: ${(p) => p.theme.size.xl};
  background: linear-gradient(
    194deg,
    rgba(47, 97, 234, 0.45) 5.94%,
    rgba(2, 2, 28, 0.45) 68.54%
  );
  align-items: center;
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
  height: 304px;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_1200}) {
    flex: unset;
    height: 412px;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    height: 288px;
  }

  @media (${MEDIA_QUERY_WIDTH_MOBILE_S}) {
    height: 343px;
  }
`;

const DescriptionContainer = styled(FlexBox)`
  padding: ${(p) => p.theme.size.xl};
  justify-content: start;
  min-height: 304px;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_1200}) {
    min-height: unset;
    flex: unset;
    justify-content: center;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    p {
      font-size: ${(p) => p.theme.size.xl};
    }
    span {
      font-size: ${(p) => p.theme.size.md};
    }
  }
`;

const CardContainer = styled.div`
  display: grid;
  grid-template: 'a b c';
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${(p) => p.theme.size.xl};
  max-width: 1264px;
  margin: 0 72px;

  & > *:nth-child(1) {
    grid-area: a;
  }
  & > *:nth-child(2) {
    grid-area: b;
  }
  & > *:nth-child(3) {
    grid-area: c;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_1200}) {
    grid-template:
      'a b'
      'c c';

    & > *:nth-child(1) {
      height: 100%;
    }

    & > *:nth-child(2) {
      height: 100%;
    }

    & > *:nth-child(3) {
      height: 400px;
      flex-direction: row;

      & > *:nth-child(1) {
        flex: 1;
        height: 100%;
        border-top-right-radius: 0;
        border-bottom-left-radius: 48px;
      }

      & > *:nth-child(2) {
        flex: 1;
      }
    }
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    & > *:nth-child(3) {
      & > *:nth-child(1) {
        border-top-right-radius: 48px;
        border-bottom-left-radius: 0;
      }
    }
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    width: 592px;
    height: unset;
    margin: 0;
    grid-template:
      'a'
      'b'
      'c';

    & > *:nth-child(3) {
      flex-direction: column;
      height: unset;
    }

    & > div {
      & > *:nth-child(1) {
        flex: 2;
      }
      & > *:nth-child(2) {
        flex: 1;
      }
    }
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    width: 100%;
  }
`;

const SliderContainer = styled(FlexBox)`
  width: 1264px;

  > div {
    border-radius: 48px;
  }

  margin: 0 72px;

  & > div:nth-child(1) {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  & > div:nth-child(2) {
    flex-shrink: 0;
    width: 504px;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_1200}) {
    flex-direction: column;

    & > div:nth-child(2) {
      width: 100%;
    }
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    width: 592px;
    & > div:nth-child(1) {
      height: 608px;
    }
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    width: 100%;
    & > div:nth-child(1) {
      align-items: flex-start;
      height: 398px;
    }

    & > div:nth-child(2) {
      padding: ${(p) => p.theme.size.xl};
      gap: ${(p) => p.theme.size.xl};

      p {
        font-size: ${(p) => p.theme.size.xl};
      }

      span {
        font-size: ${(p) => p.theme.size.md};
      }
    }
  }

  @media (${MEDIA_QUERY_WIDTH_MOBILE_M}) {
    height: 778px;
    > div:nth-child(2) {
      p {
        font-size: ${(p) => p.theme.size.xl};
      }

      span {
        font-size: ${(p) => p.theme.size.md};
      }

      gap: ${(p) => p.theme.size.xl};
      padding: ${(p) => p.theme.size.xl};
    }
  }

  @media (${MEDIA_QUERY_WIDTH_MOBILE_S}) {
    height: 771px;
  }
`;

const BtcUSDDescriptionContainer = styled(GlassPanel)`
  width: fit-content;
  height: fit-content;
  border-radius: ${(p) => p.theme.size.xl};
  word-break: break-all;

  background: radial-gradient(
    80.51% 76.77% at 100% 0%,
    rgba(31, 61, 161, 0.7) 0%,
    rgba(2, 2, 28, 0.7) 100%
  );

  svg {
    position: relative;
    width: 248px;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    width: 364px;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    width: 207px;

    padding: ${(p) => p.theme.size.md};
    span {
      font-size: ${(p) => p.theme.size.sm};
      line-height: 20px;
    }
    svg {
      width: 100px;
    }
  }
`;

const Card = styled(GlassPanel)`
  border-radius: 48px;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_1200}) {
    height: unset;
  }
  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    max-height: unset;
    height: unset;
  }
`;

const StyledFlexBox = styled(FlexBox)`
  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    gap: 40px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: calc(50% - 24px);
  width: 100%;
  height: 100%;
  justify-content: space-between;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    display: none;
  }
`;

const PrevButton = styled.button`
  background: url(${IconArrowBackDark.src});
  width: 48px;
  height: 48px;
  z-index: 1;
`;

const NextButton = styled(PrevButton)`
  transform: rotate(180deg);
  z-index: 1;
`;
const SliderImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  img {
    object-fit: cover;
  }
  background: linear-gradient(
    204deg,
    rgba(47, 97, 234, 0.45) -9.87%,
    rgba(2, 2, 28, 0.45) 42.21%
  );
`;

interface Props {
  isReverse: boolean;
}

export default function Slider({ isReverse }: Props) {
  const { t } = useTranslation('landing');
  const isDisableSwiper = useMediaQuery({
    query: `(${MEDIA_QUERY_WIDTH_DESKTOP_S})`,
  });
  const isSmallLayout = useMediaQuery({
    query: `(${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE})`,
  });
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <Wrapper>
      <ButtonContainer>
        <PrevButton ref={prevRef} />
        <NextButton ref={nextRef} />
      </ButtonContainer>
      {isDisableSwiper ? (
        <StyledFlexBox $flex $direction="column" $gap={80} $align="center">
          <SliderContainer $flex $gap="xl">
            <GlassPanel $flex $padding="xl" $align="flex-end">
              <SliderImageWrapper>
                <Image src={ImageSlider1} alt="" />
              </SliderImageWrapper>
              <BtcUSDDescriptionContainer
                $flex
                $padding="xl"
                $gap="md"
                $direction="column"
              >
                <IconBtcUSD isSmall={isSmallLayout} />
                <StyledText $fontSize="xl" $fontWeight="bold">
                  {t('stableCoinSecured')}
                </StyledText>
              </BtcUSDDescriptionContainer>
            </GlassPanel>
            <GlassPanel
              $flex
              $direction="column"
              $padding={48}
              $justify="space-between"
              $gap={48}
            >
              <FlexBox $flex $direction="column" $gap="xl">
                <StyledText as="p" $fontSize="xxxl" $fontWeight="bold">
                  {t('completelydecentralization')}
                </StyledText>
                <StyledText $textColor="textGray" $fontSize="lg">
                  {t('stablecoinBacked')}
                </StyledText>
              </FlexBox>
              <hr />
              <FlexBox $flex $direction="column" $gap="xl">
                <StyledText as="p" $fontSize="xxxl" $fontWeight="bold">
                  {t('moreStableThan')}
                </StyledText>
                <StyledText $textColor="textGray" $fontSize="lg">
                  {t('bitcoinResistance')}
                </StyledText>
              </FlexBox>
            </GlassPanel>
          </SliderContainer>
          <CardContainer>
            <Card $flex $padding="zero" $direction="column">
              <ImageContainer $flex $direction="column" $justify="center">
                <ImageSlider2 />
              </ImageContainer>
              <DescriptionContainer
                $flex
                $direction="column"
                $gap="md"
                $justify="center"
              >
                <StyledText as="p" $fontSize="xxxl" $fontWeight="bold">
                  {t('decentralized')}
                </StyledText>
                <StyledText $fontSize="lg" $textColor="textGray">
                  {t('fullyDecentralized')}
                </StyledText>
              </DescriptionContainer>
            </Card>
            <Card $flex $padding="zero" $direction="column">
              <ImageContainer $flex $direction="column" $justify="center">
                <ImageSlider3 />
              </ImageContainer>
              <DescriptionContainer
                $flex
                $direction="column"
                $gap="md"
                $justify="center"
              >
                <StyledText as="p" $fontSize="xxxl" $fontWeight="bold">
                  {t('convenient')}
                </StyledText>
                <StyledText $fontSize="lg" $textColor="textGray">
                  {t('stakeBTC')}
                </StyledText>
              </DescriptionContainer>
            </Card>
            <Card $flex $padding="zero" $direction="column">
              <ImageContainer $flex $direction="column" $justify="center">
                <ImageSlider4 />
              </ImageContainer>
              <DescriptionContainer
                $flex
                $direction="column"
                $gap="md"
                $justify="center"
              >
                <StyledText as="p" $fontSize="xxxl" $fontWeight="bold">
                  {t('secure')}
                </StyledText>
                <StyledText $fontSize="lg" $textColor="textGray">
                  {t('btcfiIsBuiltBy')}
                </StyledText>
              </DescriptionContainer>
            </Card>
          </CardContainer>
        </StyledFlexBox>
      ) : (
        <Swiper
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          grabCursor
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          modules={[Pagination, Navigation, EffectCoverflow]}
          loop
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
            enabled: true,
          }}
          initialSlide={0}
        >
          {isReverse ? (
            <>
              <SwiperSlide>
                <SliderContainer $flex $gap="xl">
                  <GlassPanel $flex $padding="xl" $align="flex-end">
                    <SliderImageWrapper>
                      <Image src={ImageSlider1} alt="" />
                    </SliderImageWrapper>
                    <BtcUSDDescriptionContainer
                      $flex
                      $padding="xl"
                      $gap="md"
                      $direction="column"
                    >
                      <IconBtcUSD />
                      <StyledText $fontSize="xl" $fontWeight="bold">
                        {t('stableCoinSecured')}
                      </StyledText>
                    </BtcUSDDescriptionContainer>
                  </GlassPanel>
                  <GlassPanel
                    $flex
                    $direction="column"
                    $padding={48}
                    $justify="flex-start"
                    $gap={48}
                  >
                    <FlexBox $flex $direction="column" $gap="xl">
                      <StyledText $fontSize="xxxl" $fontWeight="bold">
                        {t('completelydecentralization')}
                      </StyledText>
                      <StyledText
                        $textColor="textGray"
                        $fontSize="lg"
                        $whiteSpace="pre-line"
                      >
                        {t('stablecoinBacked')}
                      </StyledText>
                    </FlexBox>
                    <hr />
                    <FlexBox $flex $direction="column" $gap="xl">
                      <StyledText $fontSize="xxxl" $fontWeight="bold">
                        {t('moreStableThan')}
                      </StyledText>
                      <StyledText $textColor="textGray" $fontSize="lg">
                        {t('bitcoinResistance')}
                      </StyledText>
                    </FlexBox>
                  </GlassPanel>
                </SliderContainer>
              </SwiperSlide>
              <SwiperSlide>
                <CardContainer>
                  <Card $flex $padding="zero" $direction="column">
                    <ImageContainer $flex $direction="column" $justify="center">
                      <ImageSlider2 />
                    </ImageContainer>
                    <DescriptionContainer
                      $flex
                      $direction="column"
                      $gap="md"
                      $justify="center"
                    >
                      <StyledText $fontSize="xxxl" $fontWeight="bold">
                        {t('decentralized')}
                      </StyledText>
                      <StyledText $fontSize="lg" $textColor="textGray">
                        {t('fullyDecentralized')}
                      </StyledText>
                    </DescriptionContainer>
                  </Card>
                  <Card $flex $padding="zero" $direction="column">
                    <ImageContainer $flex $direction="column" $justify="center">
                      <ImageSlider3 />
                    </ImageContainer>
                    <DescriptionContainer
                      $flex
                      $direction="column"
                      $gap="md"
                      $justify="center"
                    >
                      <StyledText $fontSize="xxxl" $fontWeight="bold">
                        {t('convenient')}
                      </StyledText>
                      <StyledText $fontSize="lg" $textColor="textGray">
                        {t('stakeBTC')}
                      </StyledText>
                    </DescriptionContainer>
                  </Card>
                  <Card $flex $padding="zero" $direction="column">
                    <ImageContainer $flex $direction="column" $justify="center">
                      <ImageSlider4 />
                    </ImageContainer>
                    <DescriptionContainer
                      $flex
                      $direction="column"
                      $gap="md"
                      $justify="center"
                    >
                      <StyledText $fontSize="xxxl" $fontWeight="bold">
                        {t('secure')}
                      </StyledText>
                      <StyledText $fontSize="lg" $textColor="textGray">
                        {t('btcfiIsBuiltBy')}
                      </StyledText>
                    </DescriptionContainer>
                  </Card>
                </CardContainer>
              </SwiperSlide>
            </>
          ) : (
            <>
              <SwiperSlide>
                <CardContainer>
                  <Card $flex $padding="zero" $direction="column">
                    <ImageContainer $flex $direction="column" $justify="center">
                      <ImageSlider2 />
                    </ImageContainer>
                    <DescriptionContainer
                      $flex
                      $direction="column"
                      $gap="md"
                      $justify="center"
                    >
                      <StyledText $fontSize="xxxl" $fontWeight="bold">
                        {t('decentralized')}
                      </StyledText>
                      <StyledText $fontSize="lg" $textColor="textGray">
                        {t('fullyDecentralized')}
                      </StyledText>
                    </DescriptionContainer>
                  </Card>
                  <Card $flex $padding="zero" $direction="column">
                    <ImageContainer $flex $direction="column" $justify="center">
                      <ImageSlider3 />
                    </ImageContainer>
                    <DescriptionContainer
                      $flex
                      $direction="column"
                      $gap="md"
                      $justify="center"
                    >
                      <StyledText $fontSize="xxxl" $fontWeight="bold">
                        {t('convenient')}
                      </StyledText>
                      <StyledText $fontSize="lg" $textColor="textGray">
                        {t('stakeBTC')}
                      </StyledText>
                    </DescriptionContainer>
                  </Card>
                  <Card $flex $padding="zero" $direction="column">
                    <ImageContainer $flex $direction="column" $justify="center">
                      <ImageSlider4 />
                    </ImageContainer>
                    <DescriptionContainer
                      $flex
                      $direction="column"
                      $gap="md"
                      $justify="center"
                    >
                      <StyledText $fontSize="xxxl" $fontWeight="bold">
                        {t('secure')}
                      </StyledText>
                      <StyledText $fontSize="lg" $textColor="textGray">
                        {t('btcfiIsBuiltBy')}
                      </StyledText>
                    </DescriptionContainer>
                  </Card>
                </CardContainer>
              </SwiperSlide>
              <SwiperSlide>
                <SliderContainer $flex $gap="xl">
                  <GlassPanel $flex $padding="xl" $align="flex-end">
                    <SliderImageWrapper>
                      <Image src={ImageSlider1} alt="" />
                    </SliderImageWrapper>
                    <BtcUSDDescriptionContainer
                      $flex
                      $padding="xl"
                      $gap="md"
                      $direction="column"
                    >
                      <IconBtcUSD />
                      <StyledText $fontSize="xl" $fontWeight="bold">
                        {t('stableCoinSecured')}
                      </StyledText>
                    </BtcUSDDescriptionContainer>
                  </GlassPanel>
                  <GlassPanel
                    $flex
                    $direction="column"
                    $padding={48}
                    $justify="flex-start"
                    $gap={48}
                  >
                    <FlexBox $flex $direction="column" $gap="xl">
                      <StyledText $fontSize="xxxl" $fontWeight="bold">
                        {t('completelydecentralization')}
                      </StyledText>
                      <StyledText
                        $textColor="textGray"
                        $fontSize="lg"
                        $whiteSpace="pre-line"
                      >
                        {t('stablecoinBacked')}
                      </StyledText>
                    </FlexBox>
                    <hr />
                    <FlexBox $flex $direction="column" $gap="xl">
                      <StyledText $fontSize="xxxl" $fontWeight="bold">
                        {t('moreStableThan')}
                      </StyledText>
                      <StyledText $textColor="textGray" $fontSize="lg">
                        {t('bitcoinResistance')}
                      </StyledText>
                    </FlexBox>
                  </GlassPanel>
                </SliderContainer>
              </SwiperSlide>
            </>
          )}
        </Swiper>
      )}
    </Wrapper>
  );
}
